import InfoBoxForm from "@/views/info-box/infoBoxForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    infoCardPageService: "infoCardPageService",
    categoryService: "categoryService"
  }
})
export default class InfoCardForm extends mixins(InfoBoxForm) {
  model = {};
  internal_events = [];
  infoCardPage = {};
  categories = [];

  infoBoxRequiredFields() {
    return !!this.model?.infoBoxData?.enableInfoBox;
  }

  get elements() {
    const infoBoxDataFields = [
      {
        id: "enableInfoBox",
        label: this.translations.labels.infoCard_form_enableInfoBox,
        type: "switch"
      },
      ...this.infoBoxElements
    ];
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "code",
            label: this.translations.labels.infoCard_form_code,
            type: "text",
            required: true,
            rules: "min:4"
          },
          {
            id: "displayOrder",
            label: this.translations.labels.infoCard_form_order,
            type: "number",
            rules: "gt:0|lte:255",
            required: true
          },
          {
            id: "idCategory",
            label: this.translations.labels.infoCard_form_category,
            type: "select",
            items: this.categoryList,
            clearable: true,
            required: true
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.languages,
            resetColumns: true,
            elements: [
              {
                id: "title",
                label: this.translations.labels.form_title,
                type: "text",
                required: true,
              },
              {
                id: "subTitle",
                label: this.translations.labels.infoCard_form_subTitle,
                type: "text",
                required: false,
              },
              {
                id: "description",
                label: this.translations.labels.form_description,
                type: "textarea",
                required: false,
              },
              {
                id: "ctaLabel",
                label: this.translations.labels.infoCard_form_ctaLabel,
                type: "text",
                required: true,
              },
              {
                id: "imageUri",
                label: this.translations.labels.infoCard_form_imageUri,
                type: "media",
                mediaType: "image",
                required: true
              },
            ],
          }
        ]
      },
      {
        id: "infoBoxData",
        legend: this.translations.labels.infoCard_form_infoBoxData,
        type: "fieldset",
        group: "object",
        collapsible: true,
        resetColumns: true,
        elements: infoBoxDataFields
      }
    ];
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get languages() {
    if (
      this.infoCardPage.idEvent === undefined ||
      this.infoCardPage.idEvent === null ||
      this.events === undefined ||
      this.events === null
    )
      return [];
    const event = this.events.filter((e) => e.value == this.infoCardPage.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }


  get categoryList() {
    return this.categories.filter(l => l.isEnabled == true).map((l) => {
        return {
            value: l.id,
            label: l.code,
        };
    });
  }

  get helpLink() {
    return this.translations.pageHelp.infoCard;
  }

  async created() {
    this.internal_events = await this.eventService.list();
    this.categories = await this.categoryService.list();

    this.afterCreate();
  }
}
